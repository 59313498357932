<template>
  <Card sticky-footer v-if="ready">

    <div class="space-y-2 @md/card:space-y-4">
      <h2 class="text-slate-800 text-xl font-normal tracking-wide leading-[24px]">Please enter your details</h2>
      <p class="text-gray-600 font-thin text-sm tracking-wide leading-snug">The hotel requires this information for all guests.</p>
    </div>

    <CheckInForm
      v-if="primaryGuest"
      :guest="primaryGuest"
      @init-form="(formData: any) => onFormInit(formData)"
      @form-complete="(formData: Guest) => onFormComplete(formData, primaryForm)"
      @form-edit="editForm(primaryGuest as Guest)"
      @form-stop-edit="onFormStopEdit(primaryGuest as Guest)"
      @can-submit="(fieldsReady) => (canSubmit = fieldsReady)"
    />

    <template v-if="secondaryForms.length > 0">
      <div class="space-y-2 @md/card:space-y-4">
        <h2 class="text-slate-800 text-xl font-normal tracking-wide leading-[24px]">Please enter additional guests details</h2>
        <p class="text-gray-600 font-thin text-sm tracking-wide leading-snug">The hotel requires this additional information for guests to be able to process your check-in.</p>
      </div>

      <CheckInForm
        can-collapse
        v-for="({ guest, form }, index) in secondaryForms"
        :key="(guest as Guest)?.guestId"
        :guest="(guest as Guest)"
        :guest-count="index + 1"
        @init-form="(formData: any) => onFormInit(formData)"
        @form-complete="(formData: Guest) => onFormComplete(formData, form)"
        @form-edit="editForm(guest as Guest)"
        @form-stop-edit="onFormStopEdit(guest as Guest)"
      />
    </template>

    <div class="text-zinc-500 text-xs">
      <p>*By completing online check-in, you are accepting the Hotel’s Policy.</p>
      <details class="mt-1" v-if="property?.termsAndConditions">
        <summary class="cursor-pointer text-teal underline">Read Full Policy here</summary>
        <div class="p-2 border rounded-md bg-gray-50 mt-1" v-html="property?.termsAndConditions?.replaceAll('\n', '<br/>')"></div>
      </details>
    </div>

    <template #footer>
      <div class="bg-white w-full empty:hidden border-t p-3 px-0" ref="footerWrapper">
        <template v-if="secondaryForms.length === 0">
          <SButton :disabled="!canComplete" class="flex items-center justify-center relative w-full" @click="onProceed">
            <span>Complete check-in</span>
            <PhosphorIconCheckCircle size="24" weight="fill" class="absolute right-4" />
          </SButton>
        </template>
        <template v-else-if="secondaryForms.length > 0">
          <div class="flex-col flex md:flex-row md:space-x-2 items-center justify-center w-full mb-1 md:mb-3">
            <div class="text-teal text-sm space-x-1 items-center flex">
              <span class="text-nowrap">
                {{ completedFormsCount }} / {{ secondaryForms.length + 1 }} guests completed
              </span>
              <span v-if="canContinue" v-auto-animate>
                <PhosphorIconCheckCircle size="18" weight="fill" />
              </span>
            </div>
            <span class="w-full relative rounded-sm h-1 bg-teal bg-opacity-20 my-2 overflow-hidden">
              <span class="min-w-2 bg-teal absolute rounded-l-sm rounded-r-sm left-0 top-0 bottom-0 transition-all" :style="{'width': `${(100 / (secondaryForms.length + 1)) * completedFormsCount}%` }"></span>
            </span>
          </div>
          <SButton :disabled="!canContinue" class="flex items-center  justify-center relative w-full" @click="onProceed">
            <span>Complete check-in</span>
            <PhosphorIconCheckCircle v-if="canContinue" size="24" weight="fill" class="absolute right-4" />
          </SButton>
        </template>
      </div>
    </template>
  </Card>
</template>

<script lang="ts" setup>
const { $mixpanel } = useNuxtApp();
import { MP_FORM_INIT, MP_FORM_SUBMITTED } from '~/plugins/mixpanel.client';

import type { Guest } from '~/types/checkIn';
import { buildFormPayload } from './checkInFormUtils'
import { useIntersectionObserver, useStorage } from '@vueuse/core';

const {
  editForm,
  unEditForm,
  checkIn,
  formByGuestId,
  guestById,
  submitForm,
  acceptTermsAndCondition,
  initializeRegistrationForm,
  formHasError
} = useCheckInStore()

const { forms } = toRefs(useCheckInStore())

const {
  property,
  guestCount,
  reservation,
  primaryGuest,
  secondaryGuestIDs,
  reservationRequiresPayment
} = storeToRefs(useWebsiteStore())

const completedFormsCount = computed(() => {
  const _forms = forms.value
  let completedCount = 0
  for (const guestId in _forms) {
    if (_forms[guestId].isComplete) completedCount++
  }
  return completedCount
})

const footerWrapper = ref<HTMLElement | null>(null)
useIntersectionObserver(footerWrapper, (entries) => {
  if (entries[0].intersectionRatio < 1) {
    footerWrapper.value?.classList.add('border-t')
  } else {
    footerWrapper.value?.classList.remove('border-t')
  }
}, {
  threshold: [0, 1]
})

const emit = defineEmits(['proceed'])

const ready = ref(false)
onMounted(() => { ready.value = true })

const canSubmit = ref(false)
const canComplete = computed(() => canSubmit.value && !formHasError.value)
const canContinue = computed(() => completedFormsCount.value === guestCount.value)
const primaryForm = computed(() => formByGuestId(primaryGuest.value?.guestId))
const secondaryForms = computed(() => {
  if (!secondaryGuestIDs.value) return []
  const forms = []
  for (const guestId of secondaryGuestIDs.value) {
    forms.push({
      form: formByGuestId(guestId),
      guest: guestById(guestId)
    })
  }
  return forms
})

const editingForms = ref<string[]>([])
const onFormStopEdit = (guest: Guest) => {
  editingForms.value = editingForms.value.filter((guestId) => guestId !== guest.guestId)
  unEditForm(guest)
}

const onFormInit = async (formData: any) => {
  try {
    await initializeRegistrationForm(formData)
    $mixpanel(MP_FORM_INIT, { formData })
  } catch (error: any) {
    canSubmit.value = false
  }
}

const onFormComplete = async (formData: any, form: any) => {
  try {
    const payload = buildFormPayload(formData, form)
    const isSingleGuest = secondaryForms.value.length === 0
    await submitForm(payload, isSingleGuest);
    $mixpanel(MP_FORM_SUBMITTED, { formData })
  } catch (error: any) {
    throw showError({
      message: 'A fatal error occured when trying submitting your form.',
    })
  }
}

const onProceed = async () => {
  const isSingleGuest = secondaryForms.value.length === 0

  // attempt to do combined confirmation if single guest
  if (isSingleGuest) {
    const primaryGuestModel = useStorage(`shackle-${primaryGuest.value?.guestId}`, {})
    await onFormComplete(primaryGuestModel.value, primaryForm.value)
  }

  const needsPreauth = reservationRequiresPayment.value
  if (needsPreauth) {
    navigateTo('/payment')
  } else {
    if (reservation.value) {
      await acceptTermsAndCondition(reservation.value.name)
      await checkIn(reservation.value.name)
      navigateTo('/confirmation')
    }
  }
}
</script>
